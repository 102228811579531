jQuery.noConflict();
/* Site wide JS */
jQuery(document).ready(function( $ ) {
    $(function(){
        var body = $("body");
        $(window).on('load scroll resize orientationchange', function () {
            if ($(window).scrollTop() >= 65) { 
                body.removeClass('head-top').addClass("head-scroll");
            }
            else{
                body.removeClass("head-scroll").addClass('head-top');
            }
        });
    });
	$(".article").click(function() {
	  window.location = $(this).find("a").attr("href"); 
	  return false;
	});
    $(".case-tile").click(function() {
      window.location = $(this).find("a").attr("href"); 
      return false;
    });
    $("img.aligncenter").parents('p').css("text-align", "center");
	$(function() {
	    var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], object, embed"),
	    $fluidEl = $("figure");
		$allVideos.each(function() {
		  $(this)
		    // jQuery .data does not work on object/embed elements
		    .attr('data-aspectRatio', this.height / this.width)
		    .removeAttr('height')
		    .removeAttr('width');
		});
		$(window).resize(function() {
		  var newWidth = $fluidEl.width();
		  $allVideos.each(function() {
		    var $el = $(this);
		    $el
		        .width(newWidth)
		        .height(newWidth * $el.attr('data-aspectRatio'));

		  });
		}).resize();
	});
	$('.hero-slider').slick({
		dots: true,
		slidesToShow: 1,
		autoplay: false
	});
	$('.news-articles').slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 480, // tablet breakpoint
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768, // tablet breakpoint
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
			breakpoint: 992, // mobile breakpoint
				settings: "unslick"
			}
		]
	});
	$('.events').slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: false,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 480, // tablet breakpoint
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768, // tablet breakpoint
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
			breakpoint: 992, // mobile breakpoint
				settings: "unslick"
			}
		]
	});
	$('.gallery-images').slick({
		dots: true,
		arrows: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 480, // tablet breakpoint
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768, // tablet breakpoint
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 992, // tablet breakpoint
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
		]
	});
});
// Custom Animations //
jQuery(document).ready(function($) {
	$('#top-nav').delay(1000).animate({
		'top': '0',
		'opacity': '1'
	},500);
});
AOS.init({
   once: true
})